/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const RECOVERY_PASSWORD_ERROR = "RECOVERY_PASSWORD_ERROR";
export const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const REFRESH_USER = "REFRESH_USER";
export const SET_PREFERENCE = "SET_PREFERENCE";
export const SET_PREFERENCE_ERROR = "SET_PREFERENCE_ERROR";
export const SET_PREFERENCE_SUCCESS = "SET_PREFERENCE_SUCCESS";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* CHAT*/
export const CHAT_GET_MESSAGES = "CHAT_GET_MESSAGES";
export const CHAT_GET_MESSAGES_SUCCESS = "CHAT_GET_MESSAGES_SUCCESS";
export const CHAT_GET_MESSAGES_ERROR = "CHAT_GET_MESSAGES_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION = "CHAT_ADD_MESSAGE_TO_CONVERSATION";

/* COMMENTS */
export const GET_SUBMISSION_COMMENTS = "GET_SUBMISSION_COMMENTS";
export const GET_SUBMISSION_COMMENTS_SUCCESS = "GET_SUBMISSION_COMMENTS_SUCCESS";
export const GET_SUBMISSION_COMMENTS_ERROR = "GET_SUBMISSION_COMMENTS_ERROR";
export const ADD_COMMENT_TO_SUBMISSION = "ADD_COMMENT_TO_SUBMISSION";

/* SUBMISSIONS */
export const GET_SUBMISSIONS = "GET_SUBMISSIONS";
export const GET_SUBMISSION = "GET_SUBMISSION";
export const GET_SUBMISSIONS_SUCCESS = "GET_SUBMISSIONS_SUCCESS";
export const GET_SUBMISSIONS_ERROR = "GET_SUBMISSIONS_ERROR";
export const GET_SUBMISSION_SUCCESS = "GET_SUBMISSION_SUCCESS";
export const GET_SUBMISSION_ERROR = "GET_SUBMISSION_ERROR";
export const UPDATE_SUBMISSION = "UPDATE_SUBMISSION";

/* REPORTS */
export const GET_GENERAL_DATA = "GET_GENERAL_DATA";
export const GET_BUBBLE_DATA = "GET_BUBBLE_DATA";
export const GET_GENERAL_STATUS_DATA = "GET_GENERAL_STATUS_DATA";
export const GET_GENERAL_SERVICE_TIME_DATA = "GET_GENERAL_SERVICE_TIME_DATA";
export const GET_GENERAL_TYPES_DATA = "GET_GENERAL_TYPES_DATA";
export const GET_GENERAL_SOURCES_DATA = "GET_GENERAL_SOURCES_DATA";
export const GET_GENERAL_LOCATIONS_DATA = "GET_GENERAL_LOCATIONS_DATA";
export const GET_GENERAL_RELATIONSHIPS_DATA = "GET_GENERAL_RELATIONSHIPS_DATA";

export const GET_GENERAL_SENSITIVITIES_DATA = "GET_GENERAL_SENSITIVITIES_DATA";
export const GET_BUBBLE_DATA_SUCCESS = "GET_BUBBLE_DATA_SUCCESS";
export const GET_GENERAL_STATUS_DATA_SUCCESS = "GET_GENERAL_STATUS_DATA_SUCCESS";
export const GET_GENERAL_SERVICE_TIME_DATA_SUCCESS = "GET_GENERAL_SERVICE_TIME_DATA_SUCCESS";

export const GET_GENERAL_TYPES_DATA_SUCCESS = "GET_GENERAL_TYPES_DATA_SUCCESS";
export const GET_GENERAL_SOURCES_DATA_SUCCESS = "GET_GENERAL_SOURCES_DATA_SUCCESS";
export const GET_GENERAL_LOCATIONS_DATA_SUCCESS = "GET_GENERAL_LOCATIONS_DATA_SUCCESS";
export const GET_GENERAL_RELATIONSHIPS_DATA_SUCCESS = "GET_GENERAL_RELATIONSHIPS_DATA_SUCCESS";

export const GET_GENERAL_SENSITIVITIES_DATA_SUCCESS = "GET_GENERAL_SENSITIVITIES_DATA_SUCCESS";
export const GET_GENERAL_DATA_ERROR = "GET_GENERAL_DATA_ERROR";

/* LOGS */
export const REGISTER_EVENT = "REGISTER_EVENT";
export const GET_LOGS_BY_ORG = "GET_LOGS_BY_ORG";
export const GET_LOGS_BY_ORG_SUCCESS = "GET_LOGS_BY_ORG_SUCCESS";

/* ORGS */
export const GET_ORGS = "GET_ORGS";
export const GET_ORGS_SUCCESS = "GET_ORGS_SUCCESS";
export const GET_ORGS_ERROR = "GET_ORGS_ERROR";

export const GET_ORG_USERS = "GET_ORG_USERS";
export const GET_ORG_USERS_SUCCESS = "GET_ORG_USERS_SUCCESS";
export const GET_ORG_USERS_ERROR = "GET_ORG_USERS_ERROR";

export const SET_MEMBER_ORGS = "SET_MEMBER_ORGS";
export const SET_MEMBER_ORGS_SUCCESS = "SET_MEMBER_ORGS_SUCCESS";
export const SET_MEMBER_ORGS_ERROR = "SET_MEMBER_ORGS_ERROR";

export const DELETE_ORG_MEMBER = "DELETE_ORG_MEMBER";
export const DELETE_ORG_MEMBER_SUCCESS = "DELETE_ORG_MEMBER_SUCCESS";
export const DELETE_ORG_MEMBER_ERROR = "DELETE_ORG_MEMBER_ERROR";

export const GET_ORG = "GET_ORG";
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS";
export const GET_ORG_ERROR = "GET_ORG_ERROR";
export const ADD_ORG = "ADD_ORG";
export const UPDATE_ORG = "UPDATE_ORG";

export const DISABLE_ORG = "DISABLE_ORG";
export const DISABLE_ORG_SUCCESS = "DISABLE_ORG_SUCCESS";
export const DISABLE_ORG_ERROR = "DISABLE_ORG_ERROR";

/* WORKFLOW */
export const GET_SUBMISSIONS_BY_USER_LEVEL = "GET_SUBMISSIONS_BY_USER_LEVEL";
export const GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS = "GET_SUBMISSIONS_BY_USER_LEVEL_SUCCESS";
export const GET_SUBMISSIONS_BY_USER_LEVEL_ERROR = "GET_SUBMISSIONS_BY_USER_LEVEL_ERROR";
export const PROCESS_PENDING_SUBMISSIONS = "PROCESS_PENDING_SUBMISSIONS";
export const WORKFLOW_GET_SUBMISSION = "WORKFLOW_GET_SUBMISSION";
export const WORKFLOW_GET_SUBMISSION_SUCCESS = "WORKFLOW_GET_SUBMISSION_SUCCESS";
export const WORKFLOW_GET_SUBMISSION_ERROR = "WORKFLOW_GET_SUBMISSION_ERROR";
export const WORKFLOW_CLEAN_CURRENT_SUBMISSION = "WORKFLOW_CLEAN_CURRENT_SUBMISSION";
export const WORKFLOW_UPDATE_CURRENT_SUBMISSION = "WORKFLOW_UPDATE_CURRENT_SUBMISSION";
export const ADD_WORKFLOW_SUBMISSION = "ADD_WORKFLOW_SUBMISSION";
export const UPDATE_WORKFLOW_SUBMISSION = "UPDATE_WORKFLOW_SUBMISSION";

/* WORKFLOW 2 */
export const WORKFLOW2_GET_SUBMISSION = "WORKFLOW2_GET_SUBMISSION";
export const WORKFLOW2_GET_SUBMISSION_SUCCESS = "WORKFLOW2_GET_SUBMISSION_SUCCESS";
export const WORKFLOW2_GET_SUBMISSION_ERROR = "WORKFLOW2_GET_SUBMISSION_ERROR";
export const WORKFLOW2_UPDATE_SUBMISSION = "WORKFLOW2_UPDATE_SUBMISSION";
export const WORKFLOW2_UPDATE_SUBMISSION_SUCCESS = "WORKFLOW2_UPDATE_SUBMISSION_SUCCESS";
export const WORKFLOW2_UPDATE_SUBMISSION_ERROR = "WORKFLOW2_UPDATE_SUBMISSION_ERROR";
export const WORKFLOW2_ADD_SUBMISSION = "WORKFLOW2_ADD_SUBMISSION";
export const WORKFLOW2_ADD_SUBMISSION_SUCCESS = "WORKFLOW2_ADD_SUBMISSION_SUCCESS";
export const WORKFLOW2_ADD_SUBMISSION_ERROR = "WORKFLOW2_ADD_SUBMISSION_ERROR";
export const WORKFLOW2_SST_RECORD_AUDIO = "WORKFLOW2_SST_RECORD_AUDIO";
export const WORKFLOW2_SST_RECORD_AUDIO_SUCCESS = "WORKFLOW2_SST_RECORD_AUDIO_SUCCESS";
export const WORKFLOW2_SST_RECORD_AUDIO_ERROR = "WORKFLOW2_SST_RECORD_AUDIO_ERROR";

/* WORKFLOW - EVENT TAB */
export const GET_EVENT_TAB_DATA = "GET_EVENT_TAB_DATA";
export const GET_EVENT_TAB_DATA_SUCCESS = "GET_EVENT_TAB_DATA_SUCCESS";
export const GET_EVENT_TAB_DATA_ERROR = "GET_EVENT_TAB_DATA_ERROR";

/* ACTIONS */
export const GET_SUBMISSION_ACTIONS = "GET_SUBMISSION_ACTIONS";
export const GET_SUBMISSION_ACTIONS_SUCCESS = "GET_SUBMISSION_ACTIONS_SUCCESS";
export const GET_SUBMISSION_ACTIONS_ERROR = "GET_SUBMISSION_ACTIONS_ERROR";
export const SET_SUBMISSION_ACTION = "SET_SUBMISSION_ACTION";
export const SET_SUBMISSION_ACTION_SUCCESS = "SET_SUBMISSION_ACTION_SUCCESS";
export const SET_SUBMISSION_ACTION_ERROR = "SET_SUBMISSION_ACTION_ERROR";

export const DELETE_SUBMISSION_ACTION = "DELETE_SUBMISSION_ACTION";
export const DELETE_SUBMISSION_ACTION_SUCCESS = "DELETE_SUBMISSION_ACTION_SUCCESS";
export const DELETE_SUBMISSION_ACTION_ERROR = "DELETE_SUBMISSION_ACTION_ERROR";

/* ACTION MESSAGES */
export const GET_SUBMISSION_ACTION_COMMENTS = "GET_SUBMISSION_ACTION_COMMENTS";
export const GET_SUBMISSION_ACTION_COMMENTS_SUCCESS = "GET_SUBMISSION_ACTION_COMMENTS_SUCCESS";
export const GET_SUBMISSION_ACTION_COMMENTS_ERROR = "GET_SUBMISSION_ACTION_COMMENTS_ERROR";
export const SET_SUBMISSION_ACTION_COMMENT = "SET_SUBMISSION_ACTION_COMMENT";
export const SET_SUBMISSION_ACTION_COMMENT_SUCCESS = "SET_SUBMISSION_ACTION_COMMENT_SUCCESS";
export const SET_SUBMISSION_ACTION_COMMENT_ERROR = "SET_SUBMISSION_ACTION_COMMENT_ERROR";
export const DELETE_SUBMISSION_ACTION_COMMENT = "DELETE_SUBMISSION_ACTION_COMMENT";
export const DELETE_SUBMISSION_ACTION_COMMENT_SUCCESS = "DELETE_SUBMISSION_ACTION_COMMENT_SUCCESS";
export const DELETE_SUBMISSION_ACTION_COMMENT_ERROR = "DELETE_SUBMISSION_ACTION_COMMENT_ERROR";

/* TEMP USERS */
export const ADD_TEMP_USER = "ADD_TEMP_USER";
export const ADD_TEMP_USER_SUCCESS = "ADD_TEMP_USER_SUCCESS";
export const ADD_TEMP_USER_ERROR = "ADD_TEMP_USER_ERROR";

export const DELETE_TEMP_USER = "DELETE_TEMP_USER";
export const DELETE_TEMP_USER_SUCCESS = "DELETE_TEMP_USER_SUCCESS";
export const DELETE_TEMP_USER_ERROR = "DELETE_TEMP_USER_ERROR";

/* ROUTINES */
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

/* VOICE SETTINGS */
export const GET_VOICE_SETTINGS = "GET_VOICE_SETTINGS";
export const GET_VOICE_SETTINGS_SUCCESS = "GET_VOICE_SETTINGS_SUCCESS";
export const GET_VOICE_SETTINGS_ERROR = "GET_VOICE_SETTINGS_ERROR";
export const UPDATE_VOICE_SETTINGS = "UPDATE_VOICE_SETTINGS";

/* FORM SETTINGS */
export const GET_FORM_SETTINGS = "GET_FORM_SETTINGS";
export const GET_FORM_SETTINGS_SUCCESS = "GET_FORM_SETTINGS_SUCCESS";
export const GET_FORM_SETTINGS_ERROR = "GET_FORM_SETTINGS_ERROR";

export const GET_FORM_QUESTIONS_SETTINGS = "GET_FORM_QUESTIONS_SETTINGS";
export const GET_FORM_QUESTIONS_SETTINGS_SUCCESS = "GET_FORM_QUESTIONS_SETTINGS_SUCCESS";
export const GET_FORM_QUESTIONS_SETTINGS_ERROR = "GET_FORM_QUESTIONS_SETTINGS_ERROR";

export const UPDATE_FORM_SETTINGS = "UPDATE_FORM_SETTINGS";
export const UPDATE_FORM_SETTINGS_SUCCESS = "UPDATE_FORM_SETTINGS_SUCCESS";
export const UPDATE_FORM_SETTINGS_ERROR = "UPDATE_FORM_SETTINGS_ERROR";

export const UPDATE_FORM_QUESTIONS_SETTINGS = "UPDATE_FORM_QUESTIONS_SETTINGS";
export const UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS = "UPDATE_FORM_QUESTIONS_SETTINGS_SUCCESS";
export const UPDATE_FORM_QUESTIONS_SETTINGS_ERROR = "UPDATE_FORM_QUESTIONS_SETTINGS_ERROR";

/* WHATSAPP SETTINGS */
export const GET_WHATSAPP_SETTINGS = "GET_WHATSAPP_SETTINGS";
export const GET_WHATSAPP_SETTINGS_SUCCESS = "GET_WHATSAPP_SETTINGS_SUCCESS";
export const GET_WHATSAPP_SETTINGS_ERROR = "GET_WHATSAPP_SETTINGS_ERROR";

export const SET_WHATSAPP_SETTINGS = "SET_WHATSAPP_SETTINGS";
export const SET_WHATSAPP_SETTINGS_SUCCESS = "SET_WHATSAPP_SETTINGS_SUCCESS";
export const SET_WHATSAPP_SETTINGS_ERROR = "SET_WHATSAPP_SETTINGS_ERROR";

export const DELETE_WHATSAPP_SETTINGS = "DELETE_WHATSAPP_SETTINGS";
export const DELETE_WHATSAPP_SETTINGS_SUCCESS = "DELETE_WHATSAPP_SETTINGS_SUCCESS";
export const DELETE_WHATSAPP_SETTINGS_ERROR = "DELETE_WHATSAPP_SETTINGS_ERROR";

export const GET_CUSTOM_QUESTIONS_WHATSAPP = "GET_CUSTOM_QUESTIONS_WHATSAPP";
export const GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS = "GET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS";
export const GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR = "GET_CUSTOM_QUESTIONS_WHATSAPP_ERROR";

export const SET_CUSTOM_QUESTIONS_WHATSAPP = "SET_CUSTOM_QUESTIONS_WHATSAPP";
export const SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS = "SET_CUSTOM_QUESTIONS_WHATSAPP_SUCCESS";
export const SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR = "SET_CUSTOM_QUESTIONS_WHATSAPP_ERROR";

export const SET_WHATSAPP_CONFIGURATIONS = "SET_WHATSAPP_CONFIGURATIONS";
export const SET_WHATSAPP_CONFIGURATIONS_SUCCESS = "SET_WHATSAPP_CONFIGURATIONS_SUCCESS";
export const SET_WHATSAPP_CONFIGURATIONS_ERROR = "SET_WHATSAPP_CONFIGURATIONS_ERROR";

/* MARKETING ASSETS */
export const GET_MARKETING_ASSETS = "GET_MARKETING_ASSETS";
export const GET_MARKETING_ASSETS_SUCCESS = "GET_MARKETING_ASSETS_SUCCESS";
export const GET_MARKETING_ASSETS_ERROR = "GET_MARKETING_ASSETS_ERROR";

export const SET_MARKETING_ASSETS = "SET_MARKETING_ASSETS";

/* HOME PAGE SETTINGS */
export const GET_HOME_PAGE_SETTINGS = "GET_HOME_PAGE_SETTINGS";
export const GET_HOME_PAGE_SETTINGS_SUCCESS = "GET_HOME_PAGE_SETTINGS_SUCCESS";
export const GET_HOME_PAGE_SETTINGS_ERROR = "GET_HOME_PAGE_SETTINGS_ERROR";
export const SET_HOME_PAGE_SETTINGS = "SET_HOME_PAGE_SETTINGS";

/* STATUS PAGE SETTINGS */
export const GET_STATUS_PAGE_SETTINGS = "GET_STATUS_PAGE_SETTINGS";
export const GET_STATUS_PAGE_SETTINGS_SUCCESS = "GET_STATUS_PAGE_SETTINGS_SUCCESS";
export const GET_STATUS_PAGE_SETTINGS_ERROR = "GET_STATUS_PAGE_SETTINGS_ERROR";
export const SET_STATUS_PAGE_SETTINGS = "SET_STATUS_PAGE_SETTINGS";

/* FORM PAGE SETTINGS */
export const GET_FORM_PAGE_SETTINGS = "GET_FORM_PAGE_SETTINGS";
export const GET_FORM_PAGE_SETTINGS_SUCCESS = "GET_FORM_PAGE_SETTINGS_SUCCESS";
export const GET_FORM_PAGE_SETTINGS_ERROR = "GET_FORM_PAGE_SETTINGS_ERROR";
export const SET_FORM_PAGE_SETTINGS = "SET_FORM_PAGE_SETTINGS";
export const SET_FORM_PAGE_SETTINGS_SUCCESS = "SET_FORM_PAGE_SETTINGS_SUCCESS";
export const SET_FORM_PAGE_SETTINGS_ERROR = "SET_FORM_PAGE_SETTINGS_ERROR";

/* APP PAGE SETTINGS */
export const GET_APP_SETTINGS = "GET_APP_SETTINGS";
export const GET_APP_SETTINGS_SUCCESS = "GET_APP_SETTINGS_SUCCESS";
export const GET_APP_SETTINGS_ERROR = "GET_APP_SETTINGS_ERROR";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_APP_SETTINGS_SUCCESS = "SET_APP_SETTINGS_SUCCESS";
export const SET_APP_SETTINGS_ERROR = "SET_APP_SETTINGS_ERROR";

/* Files */
export const GET_FILE_DATA = "GET_FILE_DATA";
export const GET_FILE_DATA_SUCCESS = "GET_FILE_DATA_SUCCESS";
export const GET_FILE_DATA_ERROR = "GET_FILE_DATA_ERROR";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";

/* AI SUGGESTIONS */
export const GET_AI_SUGGESTION = "GET_AI_SUGGESTION";
export const GET_AI_SUGGESTION_SUCCESS = "GET_AI_SUGGESTION_SUCCESS";
export const GET_AI_SUGGESTION_ERROR = "GET_AI_SUGGESTION_ERROR";
export const UPDATE_SUBMISSION_SUGGESTION = "UPDATE_SUBMISSION_SUGGESTION";
export const UPDATE_SUBMISSION_SUGGESTION_SUCCESS = "UPDATE_SUBMISSION_SUGGESTION_SUCCESS";
export const UPDATE_SUBMISSION_SUGGESTION_ERROR = "UPDATE_SUBMISSION_SUGGESTION_ERROR";
export const GENERATE_AI_SUGGESTION = "GENERATE_AI_SUGGESTION";
export const GENERATE_AI_SUGGESTION_SUCCESS = "GENERATE_AI_SUGGESTION_SUCCESS";
export const GENERATE_AI_SUGGESTION_ERROR = "GENERATE_AI_SUGGESTION_ERROR";

/* PROPRIETARY COURSES SETTINGS */
export const GET_PROPRIETARY_COURSES_SETTINGS = "GET_PROPRIETARY_COURSES_SETTINGS";
export const GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS = "GET_PROPRIETARY_COURSES_SETTINGS_SUCCESS";
export const GET_PROPRIETARY_COURSES_SETTINGS_ERROR = "GET_PROPRIETARY_COURSES_SETTINGS_ERROR";
export const SET_PROPRIETARY_COURSES_SETTINGS = "SET_PROPRIETARY_COURSES_SETTINGS";
export const SET_PROPRIETARY_COURSES_SETTINGS_SUCCESS = "SET_PROPRIETARY_COURSES_SETTINGS_SUCCESS";
export const SET_PROPRIETARY_COURSES_SETTINGS_ERROR = "SET_PROPRIETARY_COURSES_SETTINGS_ERROR";

/* Committees */
export const GET_COMMITTEES = "GET_COMMITTEES";
export const GET_COMMITTEES_SUCCESS = "GET_COMMITTEES_SUCCESS";
export const GET_COMMITTEES_ERROR = "GET_COMMITTEES_ERROR";
export const CREATE_COMMITTEE = "CREATE_COMMITTEE";
export const CREATE_COMMITTEE_SUCCESS = "CREATE_COMMITTEE_SUCCESS";
export const CREATE_COMMITTEE_ERROR = "CREATE_COMMITTEE_ERROR";
export const DELETE_COMMITTEE = "DELETE_COMMITTEE";
export const DELETE_COMMITTEE_SUCCESS = "DELETE_COMMITTEE_SUCCESS";
export const DELETE_COMMITTEE_ERROR = "DELETE_COMMITTEE_ERROR";
export const UPDATE_COMMITTEE = "UPDATE_COMMITTEE";
export const UPDATE_COMMITTEE_SUCCESS = "UPDATE_COMMITTEE_SUCCESS";
export const UPDATE_COMMITTEE_ERROR = "UPDATE_COMMITTEE_ERROR";

/* Contracts */
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_ERROR = "GET_CONTRACTS_ERROR";
export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_ERROR = "CREATE_CONTRACT_ERROR";
export const CHANGE_CONTRACT_STATUS = "CHANGE_CONTRACT_STATUS";
export const CHANGE_CONTRACT_STATUS_SUCCESS = "CHANGE_CONTRACT_STATUS_SUCCESS";
export const CHANGE_CONTRACT_STATUS_ERROR = "CHANGE_CONTRACT_STATUS_ERROR";

/* EXPORT DATA */
export const EXPORT_DATA = "EXPORT_DATA";
export const EXPORT_DATA_SUCCESS = "EXPORT_DATA_SUCCESS";
export const EXPORT_DATA_ERROR = "EXPORT_DATA_ERROR";

export const EXPORT_DATA_BY_ORG = "EXPORT_DATA_BY_ORG";
export const EXPORT_DATA_BY_ORG_SUCCESS = "EXPORT_DATA_BY_ORG_SUCCESS";
export const EXPORT_DATA_BY_ORG_ERROR = "EXPORT_DATA_BY_ORG_ERROR";

export * from "./aiSuggestions/actions";
export * from "./appSettings/actions";
export * from "./auth/actions";
export * from "./committees/actions";
export * from "./contracts/actions";
export * from "./files/actions";
export * from "./formPageSettings/actions";
export * from "./formSettings/actions";
export * from "./homePageSettings/actions";
export * from "./marketingAssets/actions";
export * from "./menu/actions";
export * from "./org/actions";
export * from "./settings/actions";
export * from "./statusPageSettings/actions";
export * from "./user/actions";
export * from "./voiceSettings/actions";
export * from "./whatsappSettings/actions";
export * from "./workflow/actions";
export * from "./workflow/eventTab/actions";
export * from "./workflow2/actions";
